/* You can add global styles to this file, and also import other style files */

html,
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #232323;
}



html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
